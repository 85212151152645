<script lang="ts">
	import { handleInvalidInputs } from "./handleInvalidInputs.js";

	export let form: HTMLFormElement | null = null;

	let formClass = "";
	export { formClass as class };
</script>

<form bind:this={form} class={formClass} on:submit|preventDefault use:handleInvalidInputs on:change on:input>
	<slot />
</form>
